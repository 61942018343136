/*global grecaptcha*/

import Utils from 'util/Utils';
import Connection from 'data/Connection';
import LiveField from 'widgets/field/Field';
import LiveCard from 'widgets/card/Card';

///////////////////////
/////////////////////////////
//////////

/**
 * LIVE ReCaptcha Card Component (not available in Asseco namespace)
 *
 * This component authenticate anonymouse user who needs to solve captcha
 *
 * @private
 */
class LiveReCaptcha extends LiveCard {

    fName;
    lName;
    email;
    telNumber;

    /**
     * Holds reCaptcha type (standard/invisible)
     *
     * @type {String} type
     */
    type;

    /**
     * URL to Google ReCaptcha API URL
     *
     * @private {String} GOOGLE_API
     */
    static GOOGLE_API = 'https://www.google.com/recaptcha/api.js';

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id             : 'asseco-recaptcha',
            title          : a24n('ReCaptcha validation'),
            destroyOnClose : true
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Init component
     *
     * @private
     */
    initComponent() {
        this.buttons = [{
            text: a24n('Submit'),
            scope: this,
            id: 'submit-btn',
            handler: this.checkForm.createDelegate(this) //this.onValidateReCaptcha.createDelegate(this)
        }];

        super.initComponent();
    }

    checkForm() {
        this.fName = document.getElementById('asseco-recaptcha-fullName-input').value;
        this.lName = document.getElementById('asseco-recaptcha-lastName-input').value;
        this.email = document.getElementById('asseco-recaptcha-email-input').value;
        this.telNumber = document.getElementById('asseco-recaptcha-telNumber-input').value;
        var checked = document.getElementsByClassName('mdl-checkbox__input')[0].checked;

        // check if contact/consent checkbox is missing
        if(Utils.isEmpty(this.email) && Utils.isEmpty(this.telNumber)) {
            var msg = Asseco.config.lang === 'en' ? 'Missing contact. Telephone or email required' : 'Potrebno je upisati broj telefona ili email adresu';
            Utils.dialog('', a24n(msg));
            return;
        } else if (checked === false) {
            msg = Asseco.config.lang === 'en' ? 'I agree to the processing of the entered data in accordance with the General Terms and Conditions.' : 'Slažem se s procesuiranjem unesenih podataka u skladu s Općim uvjetima poslovanja.';
            
            Utils.dialog('', a24n(msg),
                [{
                    text: a24n('Ok'),
                    scope: this,
                    handler: function (d) {
                        d.close();
                        document.getElementsByClassName('mdl-checkbox__input')[0].checked = true;
                        this.onValidateReCaptcha(this);
                    }
                },
                {
                    text: a24n('Close'),
                    handler: (d) => d.close()
                }]
            );

            return;
        } else if (Utils.isEmpty(this.fName)) {
            msg = Asseco.config.lang === 'en' ? 'Missing first name' : 'Potrebno je upisati ime';
            Utils.dialog('', a24n(msg));
            return;

        } else if (Utils.isEmpty(this.lName)) {
            msg = Asseco.config.lang === 'en' ? 'Missing last name' : 'Potrebno je upisati prezime';
            Utils.dialog('', a24n(msg));
            return;
        }

        this.checkCPMAddressbook();
        this.onValidateReCaptcha(this);
    }

    /**
     * Return content for card
     *
     * @private
     */
    getContentHtml() {
        return require('babel-loader!template-string-loader!./ReCaptcha.html')({
            fName : LiveField.getTextField({
                id       : 'asseco-recaptcha-fullName',
                label    : Asseco.aTrans[Asseco.config.lang].name,
                value    : Asseco.displayName || '',
                required : true
            }),
            fLastName : LiveField.getTextField({
                id       : 'asseco-recaptcha-lastName',
                label    : Asseco.aTrans[Asseco.config.lang].lastName,
                value    : Asseco.displayLastName || '',
                required : true
            }),
            fEmail : LiveField.getTextField({
                id       : 'asseco-recaptcha-email',
                label    : Asseco.aTrans[Asseco.config.lang].email,
                value    : Asseco.displayEmail || ''
                //required : true
            }),
            fTelNumber : LiveField.getTextField({
                id       : 'asseco-recaptcha-telNumber',
                label    : Asseco.aTrans[Asseco.config.lang].telephone,
                value    : Asseco.displayTelNumber || ''
                //required : true
            }),
            type : this.type,

            sitekey : Asseco.config.reCaptchaSitekey || ''
        });
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./ReCaptcha.scss');
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // register dynamic material design components
        this.cHU(this.getEl('#asseco-recaptcha-fullName'));
        this.cHU(this.getEl('#asseco-recaptcha-lastName'));
        this.cHU(this.getEl('#asseco-recaptcha-email'));
        this.cHU(this.getEl('#asseco-recaptcha-telNumber'));

        // attach global callback for Google recaptcha
        // window.AssecoReCaptchaCb = this.onValidateReCaptcha.createDelegate(this);

        // add Google recaptcha API script
        var po = document.createElement('script');
        po.type = 'text/javascript';
        po.async = true;
        po.src = LiveReCaptcha.GOOGLE_API;

        var elem = document.querySelector('script[nonce]');
        var nonce = elem && (elem['nonce'] || elem.getAttribute('nonce'));
        if (nonce) {
            po.setAttribute('nonce', nonce);
        }
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(po, s);

        // check telephone format
        document.getElementById('asseco-recaptcha-telNumber-input').onchange = function() {
            this.telNumber = document.getElementById('asseco-recaptcha-telNumber-input').value;
            if(isNaN(this.telNumber)) {
                var msg = Asseco.config.lang === 'en' ? 'Invalid telephone format' : 'Neispravan format';
                Utils.dialog('', a24n(msg));
                return;
            }
        };
    }

    /**
     * Validate captcha
     *
     * @param {String} resp
     * @private
     */
    onValidateReCaptcha(resp) {
        var fullNameEl = this.getEl('#asseco-recaptcha-fullName-input'),
            fullNameVal = fullNameEl.value;

        // name is required before reCaptcha validation
        if (Utils.isEmpty(fullNameVal))  {
            fullNameEl.focus();
            fullNameEl.blur();
            return;
        }

        // if we have no reCaptcha response and we are using invisible reCaptcha, trigger validation
        if (typeof resp !== 'string' && this.type === 'invisible') {
            grecaptcha.execute();
            return;
        }

        if (typeof resp !== 'string') {
            resp = grecaptcha.getResponse();
        }

        if (Utils.isEmpty(resp)) {
            return;
        }

        var data = new FormData();
        data.append('recaptcha', resp);
        data.append('contextData', JSON.stringify(Asseco.contextData || {}));

        // Utils.toast(a24n('Verifying...'), 5000);
        fetch(Asseco.ChatServerUrl + '/authAdapters/reCaptcha/?validate&uuid=' + Asseco.UUID + '&displayName=' + fullNameVal, {
            method: 'post',
            body: data
        })
            // resolve response to json
            .then((r) => {
                return r.json();
            })
            // handle json
            .then((res) => {
                if (res.success) {
                    // Utils.toast(null);

                    // set display name to Asseco namespace
                    Asseco.displayName = fullNameVal;

///////////////////////////////////////////
//////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////////////////////
///////////////////////
//////////////////////////////

                    console.log('LiveReCaptcha::Got ws url: ' + res.wsUrl);
                    Connection.wsUrl = res.wsUrl;
                    Connection.connect();
                    this.hide(true);
                }
                else {
                    grecaptcha.reset();
                }
            })
            .catch((err) => {
                // Utils.toast(String.format(a24n('Error fetching token: {0}'), err.message));
                Utils.dialog(a24n('Error'), String.format(a24n('Error fetching token: {0}'), err.message));

///////////////////////////////////////
///////////////////////////////////////
//////////////////////////
            });
    }

    waitForElm(selector) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }
            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }

    reconnectWs(customerId) {
        this.waitForElm('#asseco-plans-window').then((elm) => {
            console.log('Element is ready');
            Asseco.Connection.close();
            this.waitForElm('#asseco-toast').then((elm) => { elm.remove(); });
            document.getElementsByClassName('mdl-button mdl-button--icon asseco-card-tool-icon')[0].click();
            Asseco.customer = {customer_id: customerId};
            setTimeout(function(){
                console.log('chat clicked ', Asseco.customer);
                var button = 'asseco-' + Asseco.channel + '-plugin';
                document.getElementById(button.toLowerCase()).click();
                document.getElementById('asseco-chat-plugin').click();
            }, 1500);
        });
    }

    checkCPMAddressbook() {
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        var raw;

        if(!Utils.isEmpty(this.email)) {
            raw = JSON.stringify({
                'application': 'Addressbook',
                'method': 'searchContacts',
                'parameters': {
                    'filter': [
                        {
                            'field' : 'email_query', 
                            'operator': 'equals', 
                            'value' : this.email
                        },
                        {
                            'field' : 'container_id', 
                            'operator': 'equals', 
                            'value' : '/shared/' + Asseco.config.cpmAddressbook
                        }
                    ],
                    'paging': {}
                }
            });
        } else if (!Utils.isEmpty(this.telNumber)) {
            raw = JSON.stringify({
                'application': 'Addressbook',
                'method': 'searchContacts',
                'parameters': {
                    'filter': [
                        {
                            'field' : 'telephone', 
                            'operator': 'equals', 
                            'value' : this.telNumber
                        },
                        {
                            'field' : 'container_id', 
                            'operator': 'equals', 
                            'value' : '/shared/' + + Asseco.config.cpmAddressbook
                        }
                    ],
                    'paging': {}
                }
            });
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };


        fetch(Asseco.config.liveChatRequestUrl, requestOptions)
            .then(r => {
                return r.text();
            })
            .then(result => {
                var jsonDecoded = JSON.parse(result);
                if (jsonDecoded.totalcount !== '0') {
                    this.reconnectWs(jsonDecoded.results[0].id);
                    console.log('Contact found in CPM Addressbook');
                } else {
                    this.checkPotentialClientsAddressbook();
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    checkPotentialClientsAddressbook() {
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        var raw;

        if(!Utils.isEmpty(this.email)) {
            raw = JSON.stringify({
                'application': 'Addressbook',
                'method': 'searchContacts',
                'parameters': {
                    'filter': [
                        {
                            'field' : 'email_query', 
                            'operator': 'equals', 
                            'value' : this.email
                        },
                        {
                            'field' : 'container_id', 
                            'operator': 'equals', 
                            'value' : '/shared/' + Asseco.config.potentialClientsAddressbook
                        }
                    ],
                    'paging': {}
                }
            });
        } else if(!Utils.isEmpty(this.telNumber)) {
            raw = JSON.stringify({
                'application': 'Addressbook',
                'method': 'searchContacts',
                'parameters': {
                    'filter': [
                        {
                            'field' : 'telephone', 
                            'operator': 'equals', 
                            'value' : this.telNumber
                        },
                        {
                            'field' : 'container_id', 
                            'operator': 'equals', 
                            'value' : '/shared/' + Asseco.config.potentialClientsAddressbook
                        }
                    ],
                    'paging': {}
                }
            });
        }
        
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        fetch(Asseco.config.liveChatRequestUrl, requestOptions)
            .then(r => {
                //console.log('Fetch response: ', r);
                return r.text();
            })
            .then(result => {
                //console.log(result);
                var jsonDecoded = JSON.parse(result);
                if (jsonDecoded.totalcount !== '0') {
                    this.reconnectWs(jsonDecoded.results[0].id);
                    console.log('Contact found in Potential Clients');
                } else {
                    // save contact to Potential Clients Addressbook
                    console.log('Saving contact to Potential Clients addressbook');
                    var raw = JSON.stringify({
                        'application': 'Addressbook',
                        'method': 'saveContact',
                        'parameters': 
                        {
                            'recordData':
                            {
                                'n_family': this.lName,
                                'n_given': this.fName,
                                'tel_cell_provate': this.telNumber,
                                'container_id': Asseco.config.potentialClientsAddressbook,
                                'email_home': this.email
                            },
                            'duplicateCheck': true
                        }	
                    });
                    
                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw
                    };

                    fetch(Asseco.config.liveChatRequestUrl, requestOptions)
                        .then(r => {
                            //console.log('Fetch response: ', r);
                            return r.text();
                        })
                        .then(result => {
                            console.log('Contact saved successfully');
                        })
                        .catch(err => {
                            console.error(err);
                        });
                }
            })
            .catch(err => {
                console.error(err);
            });
    }
}
LiveReCaptcha.prototype.xtype = 'LiveReCaptcha';
export default LiveReCaptcha;
